export const SET_ITEMS = '@app/cart/SET_ITEMS';
export const ADD_ITEM = '@app/cart/ADD_ITEM';
export const ADD_REPAIR = '@app/cart/ADD_REPAIR';
export const ADD_VOUCHER = '@app/cart/ADD_VOUCHER';
export const CLEAR_CART = '@app/cart/CLEAR_CART';
export const CLEAR_VOUCHER_CART = '@app/cart/CLEAR_VOUCHER_CART';
export const DELETE_ITEM = '@app/cart/DELETE_ITEM';
export const SET_NUM_BOXES = '@app/cart/SET_NUM_BOXES';
export const SET_SERVICE_PRICES = '@app/cart/SET_SERVICE_PRICES';
export const SET_DISCOUNT = '@app/cart/SET_DISCOUNT';
export const SET_VOUCHER_DISCOUNT = '@app/cart/SET_VOUCHER_DISCOUNT';
export const SET_DELIVERY = 'SET_DELIVERY';
export const SET_DOOR_TO_DOOR_CITY = 'SET_DOOR_TO_DOOR_CITY';
export const SET_DOOR_TO_DOOR_ORDER = 'SET_DOOR_TO_DOOR_ORDER';
export const SET_POST_ORDER = 'SET_POST_ORDER';
export const SET_VOUCHER_CART = 'SET_VOUCHER_CART';
export const DELETE_VOUCHER = 'DELETE_VOUCHER';
export const SET_VOUCHER_ITEMS = 'SET_VOUCHER_ITEMS';
