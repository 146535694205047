import * as z from 'zod';
import i18next from 'i18next';
import cookies from 'js-cookie';
import { DEFAULT_CITY } from '../Common/constants/city';
import { SUPPORTED_ZIP_CODE_CITIES } from '../Common/constants';

const getValidZipcodesToArray = () => {
  const city = cookies.get('city') || DEFAULT_CITY;
  const validZipCodes = localStorage.getItem(`${city}.validZipCodes`) || '';
  return validZipCodes.split(',');
};
const getValidZipcodesToObject = () =>
  SUPPORTED_ZIP_CODE_CITIES.reduce((reducer, city) => ({ ...reducer, [city]: (window.localStorage.getItem(`${city}.validZipCodes`) || '').split(',') }), {});
const setAddressesCities = (
  pickupAddressZip: string,
  deliveryAddressZip: string,
  setPickupAddressCity: (key: string) => void,
  setDeliveryAddressCity: (key: string) => void
) => {
  const validZipCodes = getValidZipcodesToObject();
  if (validZipCodes) {
    Object.entries(validZipCodes).forEach(([key, val]: any) => {
      if (val.includes(pickupAddressZip)) {
        setPickupAddressCity(key);
      }
      if (val.includes(deliveryAddressZip)) {
        setDeliveryAddressCity(key);
      }
    });
  }
};
const dynamicAddressSchema = () =>
  z.object({
    street: z.string().nonempty({ message: i18next.t('Address required') }),
    zipCode: z.string().refine((val) => {
      const validZipCodes = getValidZipcodesToArray();
      return validZipCodes.includes(val);
    }, 'false'),
  });
const dynamicShippingAddressSchema = () =>
  z.object({
    street: z.string().nonempty({ message: i18next.t('Address required') }),
    zipCode: z.string().nonempty({ message: i18next.t('Zip code required') }),
    city: z.string().nonempty({ message: i18next.t('City required') }),
    country: z.string().nonempty({ message: i18next.t('Country required') }),
  });

export { getValidZipcodesToArray, getValidZipcodesToObject, setAddressesCities, dynamicAddressSchema, dynamicShippingAddressSchema };
