import CartServicePrices from '../interfaces/service-prices';
import {
  DELETE_ITEM,
  ADD_ITEM,
  SET_ITEMS,
  CLEAR_CART,
  SET_NUM_BOXES,
  SET_SERVICE_PRICES,
  SET_DISCOUNT,
  SET_POST_ORDER,
  SET_DOOR_TO_DOOR_ORDER,
  SET_DELIVERY,
  CLEAR_VOUCHER_CART,
  SET_DOOR_TO_DOOR_CITY, ADD_REPAIR, ADD_VOUCHER, SET_VOUCHER_CART, DELETE_VOUCHER, SET_VOUCHER_ITEMS, SET_VOUCHER_DISCOUNT,
} from './types';
import { OrderDiscount, Repair, VoucherDiscount } from '../../types';
import { SupportedCities } from '../../enums';
import { VoucherItem } from 'Voucher/intefaces/item';

export function setItems(items: Omit<Repair, '_id'>[]) {
  return {
    type: SET_ITEMS,
    payload: {
      items,
    },
  };
}

export function setVoucherItems(voucherItems: Omit<VoucherItem, 'id'>[]) {
  return {
    type: SET_VOUCHER_ITEMS,
    payload: {
      voucherItems,
    },
  };
}

export function addItem(item: Omit<Repair, '_id'>) {
  return {
    type: ADD_ITEM,
    payload: {
      item,
    },
  };
}

export function addVoucher(voucherItems: Omit<VoucherItem, 'id'>) {
  return {
    type: ADD_VOUCHER,
    payload: {
      voucherItems,
    },
  };
}

export function addRepair(item: Omit<Repair, '_id'>, itemId: string) {
  return {
    type: ADD_REPAIR,
    payload: {
      item,
      itemId,
    },
  };
}

export function clearCart() {
  return {
    type: CLEAR_CART,
  };
}

export function clearVoucherCart() {
  return {
    type: CLEAR_VOUCHER_CART,
  };
}

export function deleteItem(itemId: string, repairIndex: number) {
  return {
    type: DELETE_ITEM,
    payload: {
      itemId,
      repairIndex
    },
  };
}

export function deleteVoucher(itemId: string) {
  return {
    type: DELETE_VOUCHER,
    payload: {
      itemId,
    },
  };
}

export function setNumBoxes(numBoxes: number) {
  return {
    type: SET_NUM_BOXES,
    payload: {
      numBoxes,
    },
  };
}

export function setServicePrices(servicePrices: CartServicePrices) {
  return {
    type: SET_SERVICE_PRICES,
    payload: {
      servicePrices,
    },
  };
}

export function setDiscount(discount: OrderDiscount | null) {
  return {
    type: SET_DISCOUNT,
    payload: {
      discount,
    },
  };
}

export function setVoucherDiscount(voucherDiscount: VoucherDiscount | null) {
  return {
    type: SET_VOUCHER_DISCOUNT,
    payload: {
      voucherDiscount,
    },
  };
}

export const setPostOrder = (shippingOrder: boolean) => ({
  type: SET_POST_ORDER,
  payload: {
    shippingOrder,
  },
});

export const setDoorToDoorOrder = (doorToDoorOrder: boolean) => ({
  type: SET_DOOR_TO_DOOR_ORDER,
  payload: {
    doorToDoorOrder,
  },
});

export const setVoucherCart = (voucher: boolean) => ({
  type: SET_VOUCHER_CART,
  payload: {
    voucher,
  },
});

export const setDelivery = (delivery: Partial<{ shippingOrder: boolean; doorToDoorOrder: boolean; city: SupportedCities }>) => ({
  type: SET_DELIVERY,
  payload: {
    delivery,
  },
});

export const setDoorToDoorCity = (city: SupportedCities) => ({
  type: SET_DOOR_TO_DOOR_CITY,
  payload: {
    city,
  },
});
