import CartState from '../interfaces/state';

const discountValue = localStorage.getItem('discount');
const voucherDiscountValue = localStorage.getItem('voucherDiscount');
const isVoucherEnabled = JSON.parse(localStorage.getItem('voucherItem') || 'false');

const cartInitialState: CartState = {
  items: [],
  voucherItems: [],
  numBoxes: 0,
  voucherDiscount: voucherDiscountValue ? JSON.parse(voucherDiscountValue) : null,
  servicePrices: null,
  discount: discountValue ? JSON.parse(discountValue) : null,
  delivery: {
    shippingOrder: true,
    doorToDoorOrder: false,
    city: null,
  },
  voucher: isVoucherEnabled,
};

export default cartInitialState;
