import logoGts from '../assets/logo GTS.png';
import logoHessnatur from '../assets/logo hessnature.png';
import logoMono from '../assets/logo monoquick.png';
import logoWeDress from '../assets/logo we dress.png';
import logoRevolution from '../assets/logo fashion revolution.png';

export const brandsLogos = [
    {
        img: logoGts,
        width: '200px'
    },
    {
        img: logoHessnatur,
        width: '200px'
    },
    {
        img: logoMono,
        width: '200px'
    },
    {
        img: logoWeDress,
        width: '200px'
    },
    {
        img: logoRevolution,
        width: '100px'
    },
]