import i18n from '../i18n';
import { blogItemsDe, blogItemsEn } from '../Blog/data/items';

const PAGES = {
  en: {
    aboutUs: 'about-us',
    auth: 'auth',
    blogList: 'blog',
    blogPost: 'blog/:name',
    cart: 'cart',
    checkout: 'checkout',
    checkoutFinalPrice: 'checkout/final-price',
    club: 'super-rebel-loyalty-club',
    co2: 'co2',
    createPassword: 'create-password',
    editSuccess: 'customer/:customerId/order/:orderId/edit-success',
    estimation: 'estimate/new',
    estimationSuccess: 'estimate/success',
    faq: 'faqs',
    finalPrice: 'final-price',
    forgotPassword: 'forgot-password',
    garment: 'garment',
    garmentDetails: ':garmentId/:categoryId/:typeId/:repairId',
    garmentRepair: 'garment/:garmentId',
    homepage: '/',
    imprint: 'imprint',
    jobs: 'jobs',
    login: 'login',
    logout: 'logout',
    orderEditDelivery: 'edit-delivery/:customerId/:orderId',
    orderEditNotAllowed: 'edit-not-allowed',
    orderEditPickUp: 'edit-pickup/:customerId/:orderId',
    orderStatus: 'customer/:customerId/order/:orderId/status',
    privacyPolicy: 'privacy-policy',
    product: 'product',
    productCategory: ':categoryId',
    productDetail: ':categoryId/:typeId/:repairId',
    productSelect: 'product/select',
    productSuccess: 'success/:garmentId',
    productType: ':categoryId/:typeId',
    profile: 'profile',
    rating: 'rating/:customerId/:orderId',
    register: 'registration',
    resetPassword: 'reset-password',
    select: 'select',
    tailorList: 'our-partners',
    tailorProfile: 'our-partners/:tailorName',
    terms: 'terms-and-conditions',
    verifyEmail: 'verify-email',
    voucher: 'voucher',
    voucherFinalPrice: 'checkout/voucher/final-price',
    priceGuide: 'price-guide',
    brandPartnerships: 'brand-partnerships'
  },
  de: {
    aboutUs: 'ueber-uns',
    auth: 'auth',
    blogList: 'unser-blog',
    blogPost: 'unser-blog/:name',
    cart: 'cart',
    checkout: 'checkout',
    checkoutFinalPrice: 'checkout/final-price',
    club: 'super-rebel-loyalty-club',
    co2: 'co2',
    createPassword: 'passwort-erstellen',
    editSuccess: 'customer/:customerId/order/:orderId/edit-success',
    estimation: 'estimate/new',
    estimationSuccess: 'estimate/success',
    faq: 'faqs',
    finalPrice: 'final-price',
    forgotPassword: 'passwort-vergessen',
    garment: 'garment',
    garmentDetails: ':garmentId/:categoryId/:typeId/:repairId',
    garmentRepair: 'garment/:garmentId',
    homepage: '/',
    imprint: 'impressum',
    jobs: 'karriere',
    login: 'login',
    logout: 'logout',
    orderEditDelivery: 'edit-lieferung/:customerId/:orderId',
    orderEditNotAllowed: 'bearbeiten-nicht-erlaubt',
    orderEditPickUp: 'bearbeiten-abholung/:customerId/:orderId',
    orderStatus: 'customer/:customerId/order/:orderId/status',
    privacyPolicy: 'datenschutzerklaerung',
    product: 'product',
    productCategory: ':categoryId',
    productDetail: ':categoryId/:typeId/:repairId',
    productSelect: 'product/select',
    productSuccess: 'success/:garmentId',
    productType: ':categoryId/:typeId',
    profile: 'profile',
    rating: 'rating/:customerId/:orderId',
    register: 'registriere-dich',
    resetPassword: 'passwort-zurücksetzen',
    select: 'select',
    tailorList: 'unsere-partner',
    tailorProfile: 'unsere-partner/:tailorName',
    terms: 'allgemeine-geschaeftsbedingungen',
    verifyEmail: 'verify-email',
    voucher: 'gutschein',
    voucherFinalPrice: 'checkout/voucher/final-price',
    priceGuide: 'price-guide',
    brandPartnerships: 'marken-partnerschaften'
  },
};

const getRoute = (pageName: string) => {
  return PAGES[i18n.language][pageName];
};

const getPage = (pageName: string) => {
  return `/${PAGES[i18n.language][pageName]}`;
};

const getLanguageChangePageRedirect = (prevLanguage: 'de' | 'en', language: 'de' | 'en', pathname: string) => {
  // if (pathname.includes('product')) {
  //   return pathname;
  // }

  const currentPageKey = Object.entries(PAGES[prevLanguage]).reduce((prev, [key, value]) => {
    if (key !== 'homepage' && pathname.includes(value)) {
      return key;
    }
    return prev;
  }, '');

  if (currentPageKey) {
    const newPathRoot = PAGES[language][currentPageKey];

    if (currentPageKey === 'blogList') {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let [_, blogItemName] = pathname.split(PAGES[prevLanguage][currentPageKey]);
      blogItemName = blogItemName.substring(1);
      const englishBlogs = blogItemsEn.map(({ id }) => id);
      const germanBlogs = blogItemsDe.map(({ id }) => id);

      if (language === 'de') {
        return `/${newPathRoot}/${germanBlogs[englishBlogs.indexOf(blogItemName)]}`;
      } else if (language === 'en') {
        return `/${newPathRoot}/${englishBlogs[germanBlogs.indexOf(blogItemName)]}`;
      }
    }

    return pathname.replace(PAGES[prevLanguage][currentPageKey], PAGES[language][currentPageKey]);
  }

  return '/';
};

const normalizeString = (str: string): string => str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const localizePath = (path: string, currentLanguage: string): string => {
  const pathMapping: Record<string, Record<string, string>> = {
    'en': {
      'create-password': 'create-password',
      'edit-not-allowed': 'edit-not-allowed',
      'edit-delivery': 'edit-delivery',
      'edit-pickup': 'edit-pickup'
    },
    'de': {
      'create-password': 'passwort-erstellen',
      'edit-not-allowed': 'bearbeiten-nicht-erlaubt',
      'edit-delivery': 'edit-lieferung',
      'edit-pickup': 'bearbeiten-abholung',
    },
  };

  const languageMappings = pathMapping[currentLanguage] || pathMapping['en'] || pathMapping['de'];

  const pathSegments = path.split('/').map(segment => normalizeString(segment.toLowerCase()));

  const replaceDynamicParams = (localizedSegments: string[], originalSegments: string[]): string[] => {
    return localizedSegments.map((segment, index) => {
      const hasDynamicParameters = originalSegments[index].includes(':');

      if (hasDynamicParameters) {
        const replacedDynamic = languageMappings[originalSegments[index]] || originalSegments[index];
        return replacedDynamic;
      }

      return segment;
    });
  };

  const localizedSegments = pathSegments.map(segment => {
    return languageMappings[segment] || segment;
  });

  const replacedDynamicSegments = replaceDynamicParams(localizedSegments, pathSegments);

  const localizedPath = replacedDynamicSegments.join('/');
  return localizedPath;
};

export { PAGES, getPage, getRoute, getLanguageChangePageRedirect, localizePath };