import * as axios from 'axios';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import 'reflect-metadata';

export interface OpenApiParameters {
  parameters?: Record<string, any>;
  requestBody?: any;
}

export enum HttpMethods {
  POST = 'post',
  GET = 'get',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export enum OpenApiParameterType {
  PATH = 'path',
  QUERY = 'query',
}

export interface OpenApiMethodOptions {
  method: HttpMethods;
  apiName: string;
  parameters: Array<{
    name: string;
    required?: boolean;
    in: OpenApiParameterType;
  }>;
  path: string;
  operationId: string;
}

export const OPEN_API_METHOD_META_DATA_KEY = 'openapi';

export function OpenApiMethod(options: OpenApiMethodOptions) {
  return function (target: any) {
    Reflect.defineMetadata(OPEN_API_METHOD_META_DATA_KEY, options, target);
  };
}

export class BaseOpenApiMethod<Response> {
  protected readonly options: OpenApiMethodOptions;
  private _cartId: string | null = null;
  private _cityCookie: string | null = null;

  constructor() {
    this.options = Reflect.getMetadata(OPEN_API_METHOD_META_DATA_KEY, this.constructor);
    if (window.localStorage.getItem('cart')) {
      this._cartId = window.localStorage.getItem('cart');
    }
    const cityCookie = Cookies.get('city');
    if (cityCookie) {
      this._cityCookie = cityCookie;
    }
  }

  public async call(parameters?: OpenApiParameters): Promise<Response> {
    if (process.env.NODE_ENV === 'development') {
      console.debug(`call: ${this.options.operationId}`, { parameters, options: this.options });
    }
    this.validateParameters(parameters?.parameters);

    const init = await this.buildInit(parameters?.requestBody);

    const response = await axios.default((process.env.REACT_APP_API_BASE_URL ?? 'http://localhost:3434') + this.buildPath(parameters?.parameters), {
      method: this.options.method.toLowerCase(),
      data: init.body,
      headers: init.headers,
      withCredentials: true,
    } as any);

    // if (response.headers['x-login-token']) {
    //   await this.auth
    //     .signInWithCustomToken(response.headers['x-login-token'])
    //     .then(() => {
    //       if (process.env.NODE_ENV === 'development') {
    //         console.log('Successfully logged in with login token');
    //       }
    //     })
    //     .catch(() => {
    //       console.error('Login with token failed');
    //     });
    // }

    if (response.headers.cart) {
      this._cartId = response.headers.cart;
      window.localStorage.setItem('cart', response.headers.cart);
    }

    return response.data;
  }

  private validateParameters(parameters?: Record<string, any>) {
    if (parameters) {
      for (const parameter of this.options.parameters) {
        if (parameter.required && !parameters.hasOwnProperty(parameter.name)) {
          const message = `The openapi method '${this.options.operationId}' requires the ${parameter.in} parameter '${parameter.name}'!`;
          console.error(message, Object.keys(parameters));
          throw new Error(message);
        }
        if (typeof parameters[parameter.name] !== 'string') {
          console.warn(
            `The openapi method '${this.options.operationId}' has received a ${parameter.in} parameter '${parameter.name}' that is not typeof string!`
          );
        }
      }
    }
  }

  /**
   * Checks if a firebase user exists. if not create an anonymous user.
   * Add the uid and idToken header for each request
   * @param init
   * @private
   */
  // private async addFirebaseAuthHeaders(init: Record<string, any>): Promise<void> {
  //   if (!this.auth.currentUser) {
  //     await new Promise((resolve) => this.auth.onAuthStateChanged(resolve));
  //     if (!this.auth.currentUser) {
  //       await this.auth.signInAnonymously();
  //     }
  //   }
  //
  //   if (this.auth.currentUser) {
  //     if (!init.headers) {
  //       init.headers = {};
  //     }
  //     init.headers.uid = this.auth.currentUser.uid;
  //     init.headers.customerId = this.auth.currentUser.uid;
  //     const idToken = await this.auth.currentUser.getIdToken();
  //     init.headers.authorization = `Bearer ${idToken}`;
  //     init.headers.idToken = idToken;
  //   } else {
  //     throw new Error('Could not access the current user object');
  //   }
  // }

  protected async buildInit(requestBody?: any): Promise<Record<string, any>> {
    const init: any = {
      withCredentials: true,
      body: requestBody ?? undefined,
      headers: {},
    };

    if (!this._cityCookie) {
      const cityCookie = Cookies.get('city');
      if (cityCookie) {
        this._cityCookie = cityCookie;
      }
    }
    const cityCookie = this._cityCookie;
    // await this.addFirebaseAuthHeaders(init);

    if (this._cartId) {
      if (!init.headers) {
        init.headers = {};
      }
      init.headers.cart = this._cartId;
    }

    if (cityCookie) {
      init.headers['city'] = cityCookie;
    }

    init.headers['lang'] = i18next.language || 'de';
    init.headers['Content-Type'] = 'application/json';
    return init;
  }

  protected buildPath(parameters?: Record<string, any>): string {
    let path = this.options.path;

    if (parameters) {
      for (const parameter of this.options.parameters.filter((parameter) => parameter.in === OpenApiParameterType.PATH)) {
        const value = parameters[parameter.name];
        // eslint-disable-next-line no-useless-escape
        path = path.replace(new RegExp(`\{${parameter.name}\}`), value + '');
      }

      let firstQuery = true;

      for (const parameter of this.options.parameters.filter((parameter) => parameter.in === OpenApiParameterType.QUERY)) {
        const value = parameters[parameter.name];
        if (firstQuery) {
          firstQuery = false;
          path += `?${parameter.name}=${value}`;
        } else {
          path += `&${parameter.name}=${value}`;
        }
      }

      if (this.options.parameters.some((parameter) => ![OpenApiParameterType.PATH, OpenApiParameterType.QUERY].includes(parameter.in))) {
        console.warn(
          'Some path types are not supported',
          this.options.parameters.map((p) => p.in)
        );
      }
    }

    return path;
  }
}
