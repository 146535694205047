import { Box, FormControlLabel, Modal, Typography, makeStyles, Checkbox, TextField, CircularProgress, useTheme, useMediaQuery } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import bagImage from './Luxury Bag Repair.jpeg';
import { useForm } from 'react-hook-form';
import { ReactComponent as CheckedIcon } from 'images/svg/checkbox-checked.svg';
import { Link } from '@reach/router';
import { getPage } from 'utils/pages';
import api from 'Club/api';
import CloseIcon from '@material-ui/icons/Close';
import orangeBg from './orange-background.svg';

type PageState = {
  state: '' | 'INVITED' | 'ERROR';
};

interface PopUpWindowData {
  email: string;
  personalData: boolean;
}

const useStyles = makeStyles((theme) => {
  const s = (x: number) => theme.spacing(x);
  return {
    modal: {
      position: 'absolute' as 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '100%',
      minHeight: 450,
      maxWidth: 850,
      background: '#5bbccc',
      border: '7px solid #ffffff',
      padding: '24px',
      postition: 'relative',
    },
    img: {
      width: '100%',
      maxWidth: '100%',
      height: '400px',
    },
    columnsWrapper: {
      display: 'flex',
      gap: '25px',
      alignItems: 'center',
    },
    columnsSuccessWrapper: {
      display: 'flex',
      gap: '25px',
      alignItems: 'center',
    },
    formControlabel: {
      margin: 0,
      display: 'block',
    },
    checkBoxBlock: {
      display: 'flex',
      alignItems: 'center',
      margin: '0 auto',
      minHeight: '44px',
    },
    checkBoxText: {
      fontSize: '0.8rem!important',
      color: '#ffffff',
      fontFamily: 'Work Sans, sans-serif'
    },
    textField: {
      width: '100%',
      height: '40px',
      fontSize: '0.9rem',
      border: '1px solid #000000',
      '& input::placeholder': {
        color: '#000000',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',
        },
        '&:hover fieldset': {
          borderColor: '#000000',
        },
        '& .MuiOutlinedInput-input': {
          padding: '8px 14px!important',
        },
        '&.Mui-focused fieldset': {
          borderColor: '#000000',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& .MuiInputBase-input': {
        '&:focus': {
          outline: 'none',
        },
      },
    },
    leftBlock: {
      flex: '0 1 40%',
    },
    rightBlock: {
      flex: '0 1 60%',
      marginTop: '23px',
      display: 'flex',
      textAlign: 'center',
      flexDirection: 'column',
      gap: '26px',
    },
    closeButton: {
      background: 'none',
      border: 'none',
      color: '#ffffff',
      position: 'absolute',
      top: '15px',
      right: '15px',
    },
    formFields: {
      display: 'flex',
      flexDirection: 'column',
      gap: '26px',
    },
    modalTitle: {
      fontSize: '30px',
      maxWidth: '330px',
      margin: '0 auto',
      color: '#EB672C',
      fontFamily: 'Work Sans, sans-serif'
    },
    modalText: {
      textAlign: 'left',
      fontFamily: 'Work Sans, sans-serif'
    },
    submitButton: {
      width: '100%',
      fontFamily: 'Work Sans, sans-serif',
      border: 'none',
      textTransform: 'uppercase',
      maxWidth: '272px',
      fontSize: s(2.5),
      fontWeight: 700,
      color: '#ffffff',
      background: `url(${orangeBg})`,
      padding: `${s(0.8)}px ${s(0)}px`,
      transition: '0.4s ease-in-out',
      cursor: 'pointer',
      '&:hover': {
        scale: 1.1,
      },
    },
    link: {
      color: '#ffffff',
      transition: '0.4s ease-in-out',
      textTransform: 'uppercase',
      border: 'none',
      display: 'inline-block',
      padding: '5px 10px',
      background: '#EB672C',
      '&:hover': {
        color: '#ffffff',
        transform: 'scale(1.05)',
      },
    },
    checkInboxText: {
      textAlign: 'center',
      color: '#ffffff',
      fontSize: '22px',
      margin: '0 auto',
      width: '350px',
      fontFamily: 'Work Sans, sans-serif'
    },
    discountText: {
      color: '#ffffff',
    },
  };
});

const PopUpWindow = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [modalState, setModalState] = useState<PageState>({ state: '' });
  const [open, setOpen] = useState(false);
  const [sendDisabled, setSendDisabled] = useState(true);
  const [clickCount, setClickCount] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [hasShownModal, setHasShownModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<PopUpWindowData>({
    email: '',
    personalData: false,
  });
  const { t } = useTranslation();

  const defaultValues: PopUpWindowData = {
    email: '',
    personalData: false,
  };

  const { register, watch, handleSubmit } = useForm({
    mode: 'onChange',
    defaultValues,
  });

  const watchAllFields = watch();
  
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const submitForm = async (data) => {
    setData(data);
    setModalState({ state: 'INVITED' });
    setLoading(true);
    try {
      const { success, message } = await api.registerWithDiscount(data.email);
      if (!success) {
        // Handle error
      } else {
        // Handle success
      }
    } catch (err) {
      // Handle exception
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleClick = () => {
      if (!isMobile) {
        setClickCount((prevCount) => prevCount + 1);
      }
    };

    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, [isMobile]);

  useEffect(() => {
    if (clickCount === 2 && !hasShownModal) {
      handleOpen();
      setHasShownModal(true);
      setClickCount(0);
    }

    const timer = setTimeout(() => {
      setClickCount(0);
    }, 1000); // Reset click count after 1 second

    return () => clearTimeout(timer);
  }, [clickCount, hasShownModal]);

  useEffect(() => {
    setSendDisabled(!watchAllFields.email || !watchAllFields.personalData);
    setShowButton(!!watchAllFields.email);
  }, [watchAllFields]);

  return (
    <div>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box className={classes.modal}>
          <div className={modalState.state === 'INVITED' ? classes.columnsSuccessWrapper : classes.columnsWrapper}>
            <div className={classes.leftBlock}>
              <img src={bagImage} alt="Luxury Bag Repair" className={classes.img} />
            </div>
            <div className={classes.rightBlock}>
              <h2 className={classes.modalTitle} style={{ fontWeight: 'bolder' }}>
                {modalState.state === 'INVITED' ? t('YOUR INVITATION HAS BEEN SENT!') : t('MainModalTitle')}
              </h2>
              <button className={classes.closeButton} onClick={handleClose}>
                <CloseIcon />
              </button>
              {modalState.state === 'INVITED' || (
                <>
                  <Typography component={'p'} className={classes.modalText}>
                    {t('Modal Content Top')}
                  </Typography>
                  <Typography component={'p'} className={classes.modalText}>
                    <Trans i18nKey="Modal Content Bottom" t={t} components={[<span className={classes.discountText} />]} />
                  </Typography>
                </>
              )}
              {modalState.state === 'INVITED' && (
                <Typography component={'p'} className={classes.checkInboxText}>
                  {t('Check your inbox for the invitation email')}
                </Typography>
              )}
              {modalState.state === 'INVITED' || (
                <form onSubmit={handleSubmit(submitForm)}>
                  <Box mb="1rem" className={classes.formFields}>
                    <div className={classes.textField}>
                      <TextField
                        inputRef={register}
                        name="email"
                        variant="outlined"
                        placeholder="Email"
                        InputProps={{
                          style: {
                            color: 'white',
                          },
                        }}
                        fullWidth
                        autoComplete="email"
                      />
                    </div>
                    {showButton && (
                      <Box mb="1rem" className={classes.checkBoxBlock}>
                        <FormControlLabel
                          className={classes.formControlabel}
                          control={<Checkbox checkedIcon={<CheckedIcon />} />}
                          inputRef={register}
                          label
                          name="personalData"
                        />
                        <Box pl=".5rem" textAlign={'start'}>
                          <Typography className={classes.checkBoxText} variant="body2">
                            <Trans i18nKey="Modal Check Box Text" t={t} components={[<Link className="base-link" to={getPage('privacyPolicy')} />]} />
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {showButton && (
                    <button type="submit" className={classes.submitButton} disabled={sendDisabled}>
                      {t('Registrieren')}
                    </button>
                  )}
                </form>
              )}
              {modalState.state === 'INVITED' && (
                <div style={{ margin: '0 auto', width: '100%' }}>
                  <a href="https://www.repair-rebels.com/product/select" className={classes.link}>
                    {loading ? <CircularProgress /> : t('BOOK A REPAIR')}
                  </a>
                </div>
              )}
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default PopUpWindow;
