import cartInitialState from './initial-state';
import {
  ADD_ITEM,
  ADD_REPAIR,
  DELETE_ITEM,
  CLEAR_CART,
  SET_NUM_BOXES,
  SET_ITEMS,
  SET_SERVICE_PRICES,
  SET_DISCOUNT,
  SET_DOOR_TO_DOOR_ORDER,
  SET_POST_ORDER,
  SET_DELIVERY,
  SET_DOOR_TO_DOOR_CITY,
  ADD_VOUCHER,
  SET_VOUCHER_CART,
  DELETE_VOUCHER,
  SET_VOUCHER_ITEMS,
  SET_VOUCHER_DISCOUNT,
  CLEAR_VOUCHER_CART
} from './types';
import cookies from 'js-cookie';
import { clearDiscountFromStorage } from '../utils';

export default function cartReducer(state = cartInitialState, action) {
  switch (action.type) {
    case SET_ITEMS: {
      const { items } = action.payload;
      return { ...state, items };
    }
    case SET_VOUCHER_ITEMS: {
      const { voucherItems } = action.payload;
      return { ...state, voucherItems };
    }
    case ADD_ITEM: {
      const { item } = action.payload;
      let cartData = [...(state.items ?? [])];

      cartData.push(item);
      return { ...state, items: cartData };
    }
    case ADD_REPAIR: {
      const { item, itemId } = action.payload;
      let cartData = [...(state.items ?? [])];

      cartData.find(cartProduct => cartProduct.product.itemId === itemId)?.repairs?.push(item.product);
      return { ...state, items: cartData };
    }
    case ADD_VOUCHER: {
      const { voucherItems } = action.payload;
      let cartData = [...(state.voucherItems ?? [])];
    
      cartData.push(...voucherItems);
      return { ...state, voucherItems: cartData };
    }
    case CLEAR_CART: {
      const items = [];
      clearDiscountFromStorage();
      localStorage.setItem('cart', '[]');
      cookies.set('cart', '[]');
      return { ...state, items, discount: null };
    }
    case CLEAR_VOUCHER_CART: {
      const voucherItems = [];
      localStorage.setItem('cart', '[]');
      cookies.set('cart', '[]');
      return { ...state, voucherItems };
    }
    case DELETE_ITEM: {
      const { itemId, repairIndex } = action.payload;
      let { discount, items } = state;
      let resultItems = [...items]

      const productIndex = resultItems.findIndex(product => product.product.itemId === itemId)
      resultItems[productIndex]!.repairs = resultItems[productIndex].repairs?.filter((_, idx) => idx !== repairIndex)
      resultItems = resultItems.filter(product => product.repairs?.length)

      if (!resultItems.length) {
        clearDiscountFromStorage();
        discount = null;
      }
      cookies.set('cart', JSON.stringify(resultItems));
      localStorage.setItem('cart', JSON.stringify(resultItems));
      return { ...state, items: resultItems, discount };
    }
    case DELETE_VOUCHER: {
      const { itemId } = action.payload;
      const { voucherItems } = state;
    
      const productIndex = voucherItems.findIndex((product) => product._id === itemId);
  
      if (productIndex !== -1) {
        let resultItems = [...voucherItems];
        resultItems.splice(productIndex, 1);
    
  
        cookies.set('cart', JSON.stringify(resultItems));
        localStorage.setItem('cart', JSON.stringify(resultItems));
  
        return { ...state, voucherItems: resultItems };
      }
    
      return state;
    }
    case SET_NUM_BOXES: {
      const { numBoxes } = action.payload;
      return { ...state, numBoxes };
    }
    case SET_SERVICE_PRICES: {
      const { servicePrices } = action.payload;
      return { ...state, servicePrices };
    }
    case SET_DISCOUNT: {
      const { discount } = action.payload;
      if (!discount) clearDiscountFromStorage();
      return { ...state, discount };
    }
    case SET_VOUCHER_DISCOUNT: {
      const { voucherDiscount } = action.payload;
      if (!voucherDiscount) clearDiscountFromStorage();
      return { ...state, voucherDiscount };
    }
    case SET_POST_ORDER: {
      const { shippingOrder } = action.payload;
      return {
        ...state,
        delivery: {
          ...state.delivery,
          shippingOrder,
        },
      };
    }
    case SET_DOOR_TO_DOOR_ORDER: {
      const { doorToDoorOrder } = action.payload;
      return {
        ...state,
        delivery: {
          ...state.delivery,
          doorToDoorOrder,
        },
      };
    }
    case SET_VOUCHER_CART: {
      const { voucher } = action.payload;
      const newState = { ...state, voucher };
    
      localStorage.setItem('voucherItem', JSON.stringify(voucher));
    
      return newState;
    }
    case SET_DELIVERY: {
      const { delivery } = action.payload;
      return {
        ...state,
        delivery: {
          ...state.delivery,
          ...delivery,
        },
      };
    }
    case SET_DOOR_TO_DOOR_CITY: {
      const { city } = action.payload;
      return {
        ...state,
        delivery: {
          ...state.delivery,
          city,
        },
      };
    }
    default:
      return state;
  }
}
