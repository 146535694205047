import AppState from '../../interfaces/app-state';

export const selectCartItems = (state: AppState) => state.cart.items;
export const selectSetCartVoucher = (state: AppState) => state.cart.voucher;
export const selectCartVoucherItems = (state: AppState) => state.cart.voucherItems;
export const selectNumBoxes = (state: AppState) => state.cart.numBoxes;
export const selectServicePrices = (state: AppState) => state.cart.servicePrices;
export const selectDiscount = (state: AppState) => state.cart.discount;
export const selectVoucherDiscount = (state: AppState) => state.cart.voucherDiscount;
export const selectDelivery = (state: AppState) => state.cart.delivery;
